import iconStar from "../../images/icons/styled/first/CheckInStar.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconApi from "../../images/icons/styled/first/API.svg";
import imageScheme from "../../images/schemes/escrow-account_scheme-en.svg";

export default {
    "seo": {
        "title": "Escrow account for business | Bank 131",
        "description": "Open a temporary account for dealing on online platforms. Works for marketplaces, online services, and secure deals."
    },
    "pageHeaderBlock": {
        "title": "Temporary <br /> account",
        "text": "<p>For online platforms <br />where C2C and B2C transactions take place.</p>"
    },

    "submitApplicationBlock": {
        "textSubmit": "Open account"
    },

    "infoCardsBlock": {
        "listTitle": "Zero fees",
        "list": [
            'for the escrow account service',
            'for the internal transfer from escrow  account to checking account'
        ],
        "cardsTitle": "Who needs escrow accounts",
        "cards": [
            {
                "title": 'C2C products',
                "text": 'Online platforms that connect individuals and independent professionals such as a tutor, electrician, or stylist.',
                "icon": iconStar,
                "description": '',
                "theme": 'withoutBackgroundSecond'
            },
            {
                "title": 'B2C products',
                "text": 'Online services for the companies that outsource jobs to the self-employed such as web developers, designers, or copywriters.',
                "icon": iconStar,
                "description": '',
                "theme": 'withoutBackgroundSecond'
            }
        ]
    },

    "infoCardsSecondBlock": {
        "listTitle": "Payouts",
        "list": [
            'Collected payments can be settled to bank cards or bank accounts using API.',
            'Fiscalization can be an option.',
            'Marketplace commission can be wired to a different bank account.'
        ],
        "cardsTitle": "You will like it",
        "cards": [
            {
                "title": 'Automate your operations',
                "text": 'All transactions, including multiple commission debits, take place using API. The burden on your accounting department is reduced. You control all movements of funds.',
                "icon": iconApi,
                "description": '',
                "theme": 'cardThird'
            },
            {
                "title": 'Keep client funds safe',
                "text": 'The money in the escrow account belongs to your clients and cannot be seized or frozen. If there are problems with your checking account, nothing will happen to your clients’ money.',
                "icon": iconShield,
                "description": '',
                "theme": 'cardThird'
            }
        ]
    },
    "stepsBlock": {
      "title": "How it works for a marketplace",
      "titleColor": 'second',
      "list": [
          {
              "title": 'Payment',
              "text": 'All payments go directly to the marketplace’s escrow account'
          },
          {
              "title": 'Settlement',
              "text": 'Settle to marketplace sellers automatically using the API'
          },
          {
              "title": 'Done!',
              "text": 'The commission has been credited to the marketplace checking account. Sellers have been paid for their goods and services',
              "image": imageScheme,
              "imageLabel": 'Посмотреть схему'
          }
      ]
    },
    "submitApplicationBlock": {
        "title": "Подключиться",
        "textSubmit": "Open account",
        "textSubmitBottom": "Get in touch",
    }
}