import iconStar from "../../images/icons/styled/first/CheckInStar.svg";
import iconShield from "../../images/icons/styled/first/Shield.svg";
import iconApi from "../../images/icons/styled/first/API.svg";
import imageScheme from "../../images/schemes/escrow-account_scheme.svg";

export default {
    "seo": {
        "title": "Номинальный счет для бизнеса  | Банк 131",
        "description": "Откройте номинальный счет для онлайн-платформ, на которых проводятся сделки между участниками. Для маркетплейсов, сервисов и безопасных сделок."
    },
    "pageHeaderBlock": {
        "title": "Номинальный счет <br />для бизнеса",
        "text": "<p>Для C2C и B2C онлайн-платформ, <br />на которых проводятся сделки между участниками.</p>"
    },

    "submitApplicationBlock": {
        "textSubmit": "Открыть счет"
    },

    "infoCardsBlock": {
        "listTitle": "Не берем комиссии",
        "list": [
            'За обслуживание номинального счета.',
            'За переводы между номинальным и расчетным счетами компании.'
        ],
        "cardsTitle": "Кому подойдет",
        "cards": [
            {
                "title": 'Онлайн-платформам для физических лиц',
                "text": 'Сервисам, на которых пользователи ищут исполнителей, например, электриков, репетиторов, стилистов.',
                "icon": iconStar,
                "description": '',
                "theme": 'withoutBackgroundSecond'
            },
            {
                "title": 'Онлайн-платформам для бизнеса',
                "text": 'Например, сервисам для подбора самозанятых исполнителей – дизайнеров, разработчиков, копирайтеров и других.',
                "icon": iconStar,
                "description": '',
                "theme": 'withoutBackgroundSecond'
            }
        ]
    },

    "infoCardsSecondBlock": {
        "listTitle": "Куда и как можно платить?",
        "list": [
            'На карту исполнителя/продавца с помощью API, c фискализацией или без нее.',
            'Комиссию можно перевести на свой расчетный счет с помощью интернет-банка.',
            'Переводить деньги можно на любой расчетный счет по API.'
        ],
        "cardsTitle": "Вам понравится",
        "cards": [
            {
                "title": 'Возможность автоматизировать часть операций',
                "text": 'Все операции проводятся с помощью API. Нагрузка на бухгалтерию снижается, при этом вы продолжаете контролировать все движения средств по номинальному счету.',
                "icon": iconApi,
                "description": '',
                "theme": 'cardThird'
            },
            {
                "title": 'Деньги пользователей всегда в безопасности',
                "text": 'Деньги на счете принадлежат вашим пользователям, их нельзя арестовать или заморозить. Если возникнут проблемы с расчетным счетом компании, то с деньгами клиентов на номинальном счете ничего не случится.',
                "icon": iconShield,
                "description": '',
                "theme": 'cardThird'
            }
        ]
    },
    "stepsBlock": {
      "title": "Как это работает",
      "titleColor": 'second',
      "list": [
          {
              "title": 'Платеж',
              "text": 'Деньги от плательщиков поступают на номинальный счет.'
          },
          {
              "title": 'Распределение платежа',
              "text": 'С помощью API происходят выплаты.'
          },
          {
              "title": 'Готово!',
              "text": 'Комиссия поступила на расчетный счет компании, продавцы получили выплаты за свои товары и услуги.',
              "image": imageScheme,
              "imageLabel": 'Посмотреть схему'
          }
      ]
    },
    "submitApplicationBlock": {
        "title": "Подключиться",
        "textDescription": "Отправьте заявку на открытие счета",
        "textSubmit": "Открыть счет"
    }
}