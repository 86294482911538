import React from 'react';
import PropTypes from 'prop-types';
import CircleWithImage from "./CircleWithImage";

import { Lightbox } from "react-modal-image";

import '../styles/components/stepsList.scss';

const StepsList = ({ elements, color, viewType }) => {
    const [openModalImage, setOpenModalImage] = React.useState(false);

    const onOpenModalImage = () => {
        setOpenModalImage(true)
    }

    const closeOpenModalImage = () => {
        setOpenModalImage(false)
    }

    const ListElements = React.useMemo(() => {
        return (
            <React.Fragment>
                {elements.map((listItem, i) => (
                    <li key={i}>
                        <CircleWithImage size={listItem.circleWithImageSize} color={color} check={viewType === 'ul'} className="StepsList-Icon">
                            {viewType === 'ol' && <React.Fragment>{i + 1}</React.Fragment>}
                        </CircleWithImage>
                        <h5 className="StepsList-Title">{listItem.title}</h5>
                        <div className="StepsList-Text">
                            {listItem.text}
                            {listItem.image && (
                                <React.Fragment>
                                    <div className="StepsList-LinkToImage">
                                        {openModalImage && (
                                            <Lightbox
                                                small={listItem.image}
                                                large={listItem.image}
                                                alt="Схема"
                                                imageBackgroundColor={'#fff'}
                                                onClose={closeOpenModalImage}
                                            />
                                        )}
                                    </div>
                                    <div role="button" tabIndex={0} onClick={onOpenModalImage} className="StepsList-LinkToImage" rel="noreferrer">
                                        {listItem.imageLabel}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        {listItem.image && <img src={listItem.image} alt="Изображение" className="StepsList-Image" /> }
                    </li>
                ))}
            </React.Fragment>
        );
    }, [elements, color, viewType, openModalImage]);

    const List = React.useMemo(() => {
        if (viewType === 'ul') {
            return <ul className="StepsList StepsList_theme_first">{ListElements}</ul>
        }
        return <ol className="StepsList StepsList_theme_first">{ListElements}</ol>
    }, [viewType, ListElements]);

    return (
        <React.Fragment>{List}</React.Fragment>
    );
};

export const StepsListPropsTypeElements = PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    imageLabel: PropTypes.string
}));

export const StepsListPropsTypeColor = PropTypes.oneOf(['first', 'second']);

export const StepsListPropsTypeViewType = PropTypes.oneOf(['ul', 'ol']);

export const StepsListPropsType = {
    elements: StepsListPropsTypeElements,
    color: StepsListPropsTypeColor,
    viewType: StepsListPropsTypeViewType
};

StepsList.propTypes = StepsListPropsType;

export default StepsList;


