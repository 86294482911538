import React from 'react';
import PropTypes from 'prop-types';
import Title from "../components/typography/Title";
import StepsList, {
    StepsListPropsTypeColor,
    StepsListPropsTypeElements,
    StepsListPropsTypeViewType
} from "../components/StepsList";
import Block, {BlockPropTypeConfig} from "./Block";
import BlockSection from "./BlockSection";

const StepsBlock = ({ title, list, listColor = 'first', listType = 'ul', config, color = 'grey' }) => {
    return (
        <Block config={config}>
            <div className="container">
                <BlockSection>
                    <Title innerHTML={title} color={color} level="h2" headerStyleNumb="2" />
                    <StepsList color={listColor} viewType={listType} elements={list} />
                </BlockSection>
            </div>
        </Block>
    )
};

const StepsBlockPropTypes = {
    title: PropTypes.string,
    list: StepsListPropsTypeElements,
    listColor: StepsListPropsTypeColor,
    listType: StepsListPropsTypeViewType,
    underPrev: PropTypes.bool,
    config: BlockPropTypeConfig
};

StepsBlock.propTypes = StepsBlockPropTypes;

export default StepsBlock;
